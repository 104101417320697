import { apiSlice } from "../apiSlice";



export const saleRepApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSaleReps: builder.query({
      query: () => `/admin/get-custom-all-sales-reps`,
    }),
    

    getAllSalesRepssWithParams: builder.query({
      query: (queries) =>
        `/admin/get-custom-all-sales-reps?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),

    getAllSalesrepsInvoices: builder.query({
      query: (queries) =>
        `/get-all-salesreps-invoices?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),

    getearningsalessalesreps: builder.query({
      query: (queries) =>
        `/admin/get-earnings-all-sales-reps?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),

    getSaleRep: builder.query({
      query: (user_id) => `/admin/get-single-sales-reps/${user_id}`,
    }),
    getSaleRepSates: builder.query({
      query: (user_id) =>
        `/all-sales-reps-stats-from-admin-dashboard/${user_id}`,
    }),

    getCustomSingleSalesreps: builder.query({
      query: (user_id) => `/admin/get-custom-single-sales-reps/${user_id}`,
    }),


    getSingleSalesReps: builder.query({
      query: (user_id) => `/admin/get-single-sales-reps/${user_id}`,
    }),



    getSingleSalesRepInvoice: builder.query({
      query: (body) =>
        `/get-single-salesrep-invoice/${body.user_id}/invoices/${body.id}`,
    }),

    getTwoMonthSalesRepsData: builder.query({
      query: () =>
        `/get-sales-rep-barchart-twoMonthsAgoEarningsAndCustomersCount`,
    }),
    getLastMonthSalesRepsData: builder.query({
      query: () => `get-sales-rep-barchart-lastMonthsEarningsAndCustomersCount`,
    }),
    getThisMonthSalesRepsData: builder.query({
      query: () =>
        `/get-sales-rep-barchart-thisMonthsEarningsAndCustomersCount`,
    }),
    getFutureMonthSalesRepsData: builder.query({
      query: () => `/get-sales-rep-barchart-futureEarningsAndCustomersCount`,
    }),
    createSaleRep: builder.mutation({
      query: (body) => ({
        url: `/admin/add-sales-reps`,
        method: "POST",
        body,
      }),
    }),
    updateSaleRep: builder.mutation({
      query: (body) => ({
        url: `/admin/update-sales-reps/${body.user_id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteSaleRep: builder.mutation({
      query: (user_id) => ({
        url: `/admin/delete-sales-reps/${user_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetearningsalessalesrepsQuery,
  useGetAllSalesrepsInvoicesQuery,
  useGetAllSalesRepssWithParamsQuery,
  useGetAllSaleRepsQuery,
  useGetSaleRepQuery,
  useGetSaleRepSatesQuery,
  useGetCustomSingleSalesrepsQuery,
  useGetSingleSalesRepInvoiceQuery,
  useGetTwoMonthSalesRepsDataQuery,
  useGetLastMonthSalesRepsDataQuery,
  useGetThisMonthSalesRepsDataQuery,
  useGetFutureMonthSalesRepsDataQuery,
  useCreateSaleRepMutation,
  useUpdateSaleRepMutation,
  useDeleteSaleRepMutation,
} = saleRepApiSlice;



